import React, { useLayoutEffect } from 'react';
import "../CSS/AboutUS.css";
import Footer from "../Component/Footer";
import Accordion from 'react-bootstrap/Accordion';

import laserTreatment from "../Images/laser-treatment.webp";
import AntiAging from "../Images/Anti Aging.jpg";

import Cosmotic from "../Images/cosmetic-dermatology.png";

import WhyUsLogo from "../Images/Skin_Care.png";

import SkinAndhair from "../Images/Laser-skin-treatment.jpg"

import WhyUsMainImage from "../Images/Why Us Image.webp";

import Doctor01 from "../Images/Doctor image 01.jpg";
import Doctor02 from "../Images/Doctor Image 02.webp"

import { FaUser } from 'react-icons/fa';
import { BsFillBookFill } from 'react-icons/bs';
import { TbHeartRateMonitor } from 'react-icons/tb';
import { MetaTags } from 'react-meta-tags';



const AboutUS = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>


            <MetaTags>
                <title>Laser Hair Removal In Juhu | D-Posh Skin Hair & Laser Clinic</title>
                <meta title="Laser Hair Removal In juhu |D-Posh Skin Hair & Laser Clinic" />
                <meta
                    name="description"
                    content="Experience smooth and hair-free skin with laser Hair Removal In Juhu. Our advanced technology ensures long-lasting results. Book your session now!"
                />
                <meta
                    name="keywords"
                    content="skin care clinic,skin care treatment for men,laser skin care clinic,hair treatment in juhu,hair treatment for men,skin care management in juhu,laser hair removal in juhu,cosmatic dermatology in juhu,best skin care management,skin care hospital."
                />
                <link rel="canonical" href="https://dposhskinclinic.com/laser-hair-removale-in-juhu" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Laser Hair Removal In juhu |D-Posh Skin Hair & Laser Clinic" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dposhskinclinic.com/" />
                <meta property="og:description" content="Experience smooth and hair-free skin with laser Hair Removal In juhu. Our advanced technology ensures long-lasting results. Book your session now!" />
                <meta property="og:image" content="https://dposhskinclinic.com/static/media/logo.b91fca5916adf1129377.png" />
            </MetaTags>



            {/* //////////////////// Section 1 ////////////////////////// */}

            <section className='AboutUs-Section1'>
                <div className='AboutUs-Section-Banner'>
                    <div className='AboutUs-Section-Image'>
                    </div>
                    <div className='AboutUs-Section-text'>
                        <h2>About Us</h2>
                    </div>
                </div>
            </section>

            {/* //////////////////// Section 2 ////////////////////////// */}
            <section className='AboutUs-Section2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            <div className='AboutUs-Section-Images-con'>
                                <div className='AboutUs-Section-Images'>
                                    <div className='Two-Image-con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='AboutUs_image-1'>
                                                    <img src={laserTreatment} alt="laser Treatment" />
                                                </div>
                                                <div className='AboutUs_image-3'>
                                                    <img src={Cosmotic} alt="Cosmotic" />
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='AboutUSSin'>
                                                    <div className='AboutUs_image-2'>
                                                        <img src={AntiAging} alt="AntiAging" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className='AboutUs-Section-Detail-con'>
                                <div className='AboutUs-Section-Detail'>
                                    <h5> About Us</h5>
                                    <h1>We Have 20+ Years Experience.</h1>
                                    <p>20 years of experience in non-invasive & non-surgical skin treatments have given us a rare insight into Indian clients and their needs.</p>
                                    <p>This has enabled us to select and create new treatment plans with the least downtime and virtually painless skin treatments. All dermatology concerns also addressed with care and understanding of the impact of the disease on the patient.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////// Section 6 //////////////////////////////////////// */}

            <section className='About-Section6'>
                <div className='container'>
                    <div className='About-Section6-Heading'>
                        <h2>About Founder</h2>
                    </div>
                    <div className='AboutUs-Founder'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='AboutUs-Founder-Image-con'>
                                    <div className='AboutUs-Founder-Image'>
                                        <img src={Doctor02} alt="Skin Care Doctor" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Founder-Details'>
                                    <div className='Founder-Info'>
                                        <p>
                                            Meet our founder, director, skin clinician and to add to the list, beauty
                                            entrepreneur, Khushboo Kotian. From being a Pharmacist and certified Cosmetologist
                                            and pursuing the Master’s Degree in Cosmetic Science and Public Health from
                                            NewYork and working in the beauty industry for over 6 years along side well known
                                            medical professionals, surgeons and wellness experts, Khushboo felt there was a gap
                                            for high end medical grade treatments that provide outstanding results with quality
                                            client care, in a boutique environment. This is when Dposh Skin ,Hair and Laser clinic
                                            was born
                                        </p>
                                        <p>
                                            From regularly traveling to the US to research the best technology and brands in the market and bringing them back to the Indian consumer as per their skin type and needs, Khushboo wants and continues to only partner with exclusive companies and brands that she believes are at the forefront of the industry. Khushboo believes in providing life-changing results on a holistic cross-scientific level and giving confidence back to individuals helping them be the best version they can be of themselves. Khushboo believes in giving back to a greater cause and continues to work on her efforts by connecting her business with organizations and charities she truly values.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ///////////////////////////////////// Section 7 //////////////////////////////////////// */}

            <section className='AboutUs-Section7'>
                <div className='container'>
                    <div className='OurStanDards'>
                        <div className='OurStanDards-Heading'>
                            <h2>Our Standards - An Effective And Elite Approach</h2>
                        </div>
                        <div className='OurStanDards-Accordian'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>1. Medical Accreditation</Accordion.Header>
                                    <Accordion.Body>
                                        <h3>Dposh Skin , Hair and Laser clinic is led by medical director </h3>
                                        <p>
                                            Dr. Ragini Tripathi oversees our operations. Dr. Ragini comes with a robust experience of more than 5 years and with the medical accreditation to offer the highest caliber of aesthetic services like Acne Treatment, Acne Scar Removal, Laser Hair Reduction, Pigmentation, Fillers, Skin Rejuvenation, Anti-Ageing, Chemical Peeling, Skin Tightening, Tattoo Removal, Pre-Bridal aesthetic treatments, Cosmetic
                                        </p>
                                        <h3>
                                            Plastic Surgeries.
                                        </h3>
                                        <p>
                                            Dr. Pradeep B Vishwakarma serves his expertise as a consultant doctor and is one of the best cosmetologists in Mumbai with 12 Years of experience in the field of aesthetics and cosmetology. He has been trained and certified in Advance cosmetology treatments like Botox, Fillers, Threads, PRP & other skincare solutions, Cosmetic Plastic Surgeries, Non-surgical hair restoration, and Hair transplants.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>2. Exceptional Staff</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            We are staffed by capable, experienced beauty therapists and technicians with specialized aesthetic training, to provide you with safe, effective, and comfortable medspa treatments.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>3. Cutting-Edge Technology</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            We use state-of-the-art technological equipment to ensure that our treatments are
                                            comfortable and highly effective. Our modern laser machines can treat all skin tones.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>4. Results-Driven</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Over the 5 years, Dposh has built a reputation for exceptional service with 5-star reviews, thanks to our ability to provide visible, long-lasting results and a high level of personalized care
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>

                </div>

            </section>

            {/* //////////////////// Section 3 ////////////////////////// */}

            <section className='WhyUs-Section3'>
                <div className='WhyUs-Title'>
                    <h6>Benefits</h6>
                    <h2>Why Choose Us</h2>
                </div>

                <div className='Why-Us-con'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='Why-Us-Details'>
                                <div className='Why-Us-Logo'>
                                    <img src={WhyUsLogo} alt="skin care hospital" />
                                </div>
                                <div className='Why-Us-Info'>
                                    <h4>
                                        20 Years Of Dermatology Experience
                                    </h4>
                                    <p>
                                        20 years of experience has enabled us to select and create new treatment plans with the least downtime and virtually painless skin treatments. All dermatology concerns are also addressed with care and understanding of the impact of the disease on the patient.
                                    </p>
                                </div>
                            </div>

                            <div className='Why-Us-Details'>
                                <div className='Why-Us-Logo'>
                                    <img src={WhyUsLogo} alt="best skin care tratment" />
                                </div>
                                <div className='Why-Us-Info'>
                                    <h4>
                                        A Leader In Dermatologic Care
                                    </h4>
                                    <p>
                                        We strongly believe in optimum care and comfort for each & high success rate in minimum time. So we are working as a leader in dermatology care.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='Why-us-Images-con'>
                                <div className='Why-Us-Images'>
                                    <img src={WhyUsMainImage} alt="Skin Effectcs" />
                                </div>

                            </div>

                        </div>
                        <div className='col-lg-4'>
                            <div className='Why-Us-Details'>
                                <div className='Why-Us-Logo'>
                                    <img src={WhyUsLogo} alt="Dermatology Clinic" />
                                </div>
                                <div className='Why-Us-Info'>
                                    <h4>
                                        Clinical Dermatology Research
                                    </h4>
                                    <p>
                                        High quality and accurate clinical research in dermatologic disorders, to lead to improved disease control and quality of life for those affected by dermatologic disorders.
                                    </p>
                                </div>
                            </div>

                            <div className='Why-Us-Details'>
                                <div className='Why-Us-Logo'>
                                    <img src={WhyUsLogo} alt="Best Skin Clinic" />
                                </div>
                                <div className='Why-Us-Info'>
                                    <h4>
                                        Skilled, experienced medical providers
                                    </h4>
                                    <p>
                                        D posh clinic utilizes advanced facilities and modern equipment/technology under the hands of professional experts & provides you with 100% satisfactory results without compromising your care & comfort.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////////////// section add ////////////////////////////////////// */}

            <section className='AboutUs_Section5'>
                <div className='container'>
                    <div className='About-D-Posh'>
                        <div className='About-D-Posh-Heading'>
                            <h2>
                                D-Posh Skin Hair & Laser Clinic
                            </h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='D-Posh-ImageCon'>
                                    <div className='D-Posh-Image'>
                                        <img src={SkinAndhair} alt="skin care management in andheri" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='D-Posh-Details-Con'>
                                    <div className='D-Posh-Details'>
                                        <div className='D-Posh-Heading'>
                                            <p>At Dposh Skin hair and laser clinic, we bring together the art and science of medicine by using the latest applicable innovations and techniques in Cosmetic and aesthetic procedures. The highest quality care is our promise to you. Facial rejuvenation, non-surgical hair restoration, Botox, Fillers, med facials, Body contouring, threads Etc are just a few of the ways we help restore patients to more youthful healthy skin.</p>
                                        </div>

                                        <div className='Misson-Con'>
                                            <div className='Mission'>
                                                <h2>Mission</h2>
                                                <p>
                                                    As a faith-based company, love is at the core of our values. Everyone is welcome at Dposh we make it our mission to provide caring, devoted service to every individual, no matter who they are or where they come from. This is why we invest in laser technologies that suit all skin tones and why we aim to keep our pricing accessible for all.
                                                </p>
                                                <p>
                                                    It’s our goal to make every person who walks through our doors feel safe, valued, and pampered. We honor the incredible amount of trust you place in us when you lay down in our treatment room for your laser hair removal or dermal filler session.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //////////////////// Section 4 ////////////////////////// */}

            <section className='professional-Section'>
                <div className='container'>
                    <div className='professional-Section-title'>
                        <h6>Team</h6>
                        <h2>Our Professional</h2>
                    </div>

                    <div className='Doctor-Details'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='Doctor-profile'>
                                    <div className='Doctor-Image-con'>
                                        <div className='Doctor-Photo'>
                                            <div className='Doctor-Image'>
                                                <img src={Doctor02} alt="Skin specialist" />
                                            </div>
                                        </div>
                                        <div className='Doctor-Info'>
                                            <h3>Dr Ragini Tripathi </h3>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <FaUser />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>Medical Director ( Dposh skin clinic )</h5>
                                                </div>
                                            </div>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <BsFillBookFill />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>Cosmetologist ( Mumbai )</h5>
                                                </div>
                                            </div>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <TbHeartRateMonitor />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>7 years of experience</h5>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='Doctor-profile'>
                                    <div className='Doctor-Image-con'>
                                        <div className='Doctor-Photo'>
                                            <div className='Doctor-Image'>
                                                <img src={Doctor01} alt="Dermatology Doctors" />
                                            </div>
                                        </div>
                                        <div className='Doctor-Info'>
                                            <h3>Dr Pradeep B Vishwakarma </h3>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <FaUser />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>Head Consultant ( Dposh skin clinic ) </h5>
                                                </div>
                                            </div>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <BsFillBookFill />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>Cosmetologist ( Mumbai )</h5>
                                                </div>
                                            </div>
                                            <div className='Doctor-MoreDetails'>
                                                <div className='Doctor-More-logo'>
                                                    <TbHeartRateMonitor />
                                                </div>
                                                <div className='Doctor-More-text'>
                                                    <h5>14 Years of experience</h5>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            <Footer />



        </>
    )
}

export default AboutUS