import React, { useState, useLayoutEffect } from 'react';
import "../CSS/Services.css";
import ServicesData from '../Component/ServicesData';
import { Link } from 'react-router-dom';
import Footer from "../Component/Footer"
import { MetaTags } from 'react-meta-tags';

const Services = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [eventData, setEventdata] = useState(ServicesData)
    const [dataId, setDataId] = useState("")
    console.log(eventData)

    const Function01 = (index, item) => {
        setDataId(index.id)
        console.log(index.id)
    }
    return (
        <>

            <MetaTags>
                <title>Skin Care Clinic | D-Posh Skin Hair & Laser Clinic</title>
                <meta title="Skin Care Clinic | D-Posh Skin Hair & Laser Clinic" />
                <meta
                    name="description"
                    content="Get your Skin problem solutions at Skin Care Clinic. Our expert team offers personalized treatments for a radiant and flawless complexion."
                />
                <meta
                    name="keywords"
                    content="skin care clinic,skin care treatment for men,laser skin care clinic,hair treatment in andheri,hair treatment for men,skin care management in andheri,laser hair removal in andheri,cosmatic dermatology in andheri,best skin care management,skin care hospital."
                />
                <link rel="canonical" href="https://dposhskinclinic.com/skin-care-clinic" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Skin Care Clinic | D-Posh Skin Hair & Laser Clinic" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://dposhskinclinic.com/" />
                <meta property="og:description" content="Get your Skin problem solutions at Skin Care Clinic. Our expert team offers personalized treatments for a radiant and flawless complexion." />
                <meta property="og:image" content="https://dposhskinclinic.com/static/media/logo.b91fca5916adf1129377.png" />
            </MetaTags>



            {/* //////////////////// Section 1 ////////////////////////// */}

            <section className='Services-Section1'>
                <div className='Services-Section-Banner'>
                    <div className='Services-Section-Image'>
                        {/* <img/> */}
                    </div>
                    <div className='Services-Section-text'>
                        <h2>Services</h2>
                    </div>
                </div>
            </section>

            {/* //////////////////// Section 2 ////////////////////////// */}


            <section className='Services-Section2'>
                <div className='All-Servies'>
                    <div className='All-Servies-text'>
                        <h1>Our Services</h1>
                    </div>

                    <div className='row'>
                        {
                            ServicesData.map((item, index) => {

                                const { id, Images, ServicesName, ShortDescription, Path } = item;

                                return (
                                    <>
                                        <div className='col-lg-4 col-md-6 col-sm-6'>
                                            <div className='HomeService-Card-Body'>
                                                <div className='HS-card-Image'>
                                                    <img src={Images} alt="laser hair removal in andheri" />
                                                </div>
                                                <div className='HomeSection-Card-details' id="style-1">
                                                    <h6>{ServicesName}</h6>
                                                    <p>
                                                        {ShortDescription}
                                                    </p>
                                                </div>
                                                <div className='Services-button'>
                                                    <Link to={`${Path}`}>
                                                        <button>
                                                            Learn More
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default Services