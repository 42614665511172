import Image01 from "../Images/Dermapeel.png";
import Service01 from "../Images/Services01.jpg";
import laserTreatment from "../Images/laser-treatment.webp";
import HairTreatment from "../Images/Hair Prp.jpg";
import COSMATICDERMATOLOGY from "../Images/cosmetic-dermatology.png";
import SKINCAREMANAGMENT from "../Images/SKIN CARE MANAGMENT.jpg";
// import Dermapeel from "../Images/Dermapeel.png";
// import LaserHairRemoval from "../Images/laser-hair-removal.jpg";



const GalleryData = [
    {
        id: 1,
        GImage:Image01,
    },
    {
        id: 2,
        GImage:Service01,
    },
    {
        id: 3,
        GImage:laserTreatment,
    },
    {
        id: 4,
        GImage:HairTreatment,
    },
    {
        id: 5,
        GImage:COSMATICDERMATOLOGY,
    },
    {
        id: 6,
        GImage:SKINCAREMANAGMENT,
    },
];
export default GalleryData;