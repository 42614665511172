import React, { useState } from 'react';
import "../CSS/NavBar.css";
import NavLogo from "../Images/logo.png"

import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { AiOutlineMail } from 'react-icons/ai';
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrTwitter } from 'react-icons/gr';
import { FiPhoneCall, FiClock } from 'react-icons/fi';
import { AiFillYoutube } from 'react-icons/ai';



const NavBar = () => {

        const [color, setColor] = useState(false)

        const changeColor = () => {
            if (window.scrollY >= 78) {
                setColor(true)
            } else {
                setColor(false)
            }
        }

        window.addEventListener('scroll', changeColor)

    return (
        <>


            <div className="nav-bar-component">
                <section className="NavSection">
                    <div className="container">
                        <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                            <Container>
                                {/* <Navbar.Brand href="#home">Logo</Navbar.Brand> */}
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Brand href="#home">
                                    <div className='NavLogo'>
                                        <img src={NavLogo} alt="" />
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                                    <Nav>
                                        <Nav.Link href="#home"> <Link to="/">Home</Link> </Nav.Link>
                                        <Nav.Link href="#Services"> <Link to="/skin-care-clinic">Services</Link> </Nav.Link>
                                        <Nav.Link href="#Contact us"> <Link to="/hair-treatment-for-men">Gallery</Link> </Nav.Link>
                                        <Nav.Link href="#About us"> <Link to="/laser-hair-removale-in-juhu">About Us</Link> </Nav.Link>
                                        <Nav.Link href="#Contact us"> <Link to="/contact-us">Contact Us</Link> </Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                                <Nav className="Social-media-icons">
                                    <Nav.Link><AiFillYoutube /></Nav.Link>
                                    <Nav.Link href='https://www.instagram.com/dposhskinclinic/' target="_blank"><GrInstagram /></Nav.Link>
                                </Nav>
                            </Container>
                        </Navbar>
                    </div>
                </section>
            </div>

        </>
    )
}

export default NavBar