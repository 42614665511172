import React from 'react';
import "../CSS/Footer.css";
import { Link } from 'react-router-dom';
import { GrFacebookOption } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { IoIosCall } from 'react-icons/io';
import { RiSendPlaneFill } from 'react-icons/ri';

import FooterLogo from "../Images/logo.png";


const Footer = () => {
    return (
        <>
            {/* <!-- ===================================== Footer Section ====================== --> */}
            <div class="footer-basic">
                <footer className='Footer'>
                    <div className='row'>
                        <div className='col-md-4 order-lg-1 order-2'>
                            <div className='Footer-col-1'>
                                <div className='Acting-logo-con'>
                                    <h4>Opening Hours</h4>
                                    <p>Tues – Sun</p>
                                    <p>10:30 am to 7 pm</p>
                                    <p>Mon - Weekly Off</p>
                                </div>
                                <div className='Footer-Info'>
                                    <p>D posh Skin Clinic, providing the best skincare and cosmetic services that science and technology can offer. This international quality clinic is the outcome of more than 20 years of determined efforts. D posh Clinic are equipped with a world-class state of technology.</p>
                                </div>

                                <div className='Footer-Social'>
                                    <div className='Footer_Social-Media'>
                                        <span><a href='https://www.instagram.com/dposhskinclinic/' target="_blank"><AiFillInstagram /></a></span>
                                        <span><a href='' target="_blank"><AiFillYoutube /></a></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 order-lg-2 order-3'>
                            {/* <div className='Footer-col-2'>

                            </div> */}
                            <div className='Footer-Main-Image'>
                                <div className='FooterImageCon'>
                                    <div className='FooterLogo'>
                                        <img src={FooterLogo} alt="" />
                                    </div>
                                </div>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><Link to="/">Home</Link></li>
                                    <li class="list-inline-item"><Link to="/skin-care-clinic">Services</Link></li>
                                    <li class="list-inline-item"><Link to="/laser-hair-removale-in-andheri">About Us </Link></li>
                                    <li class="list-inline-item"><Link to="/skin-care-treatment-for-men">Contact Us</Link></li>
                                </ul>

                                <p class="copyright">© Copyright D-Posh. All Rights Reserved</p>
                                <p class="copyright"><a target="_blank" href="https://skdm.in/">Designed by <strong style={{ color: "var(---TheamGoldColor)" }}>Shree Krishna Digital Marketing</strong></a></p>
                            </div>
                        </div>
                        <div className='col-md-4 order-lg-3 order-1'>
                            <div className='Footer-col-3'>
                                <div className='FooterContact'>
                                    <h4>Contact Us</h4>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <FaMapMarkedAlt />
                                        </div>
                                        <div className='FooterText'>
                                            <h4>La Blanc society
                                                B wing, 208 2 nd floor
                                                Opp J W Marriott hotel
                                                Juhu Tara Road
                                                Mumbai -400049</h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <IoIosCall />
                                        </div>
                                        <div className='FooterText'>
                                            <h4> <a href="tel:9326730897">+91-9326730897</a></h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <RiSendPlaneFill />
                                        </div>
                                        <div className='FooterText'>
                                            <h4><a href="mailto: dposhskinclinic37@gmail.com">dposhskinclinic37@gmail.com</a></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default Footer