import React, { useState, useLayoutEffect } from 'react';
import "../CSS/GalleryPage.css";
import GalleryData from '../Component/Gallerydata';
import Model from '../Component/Model';
import Footer from '../Component/Footer';
import { MetaTags } from 'react-meta-tags';

const GalleryPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const [clickImage, setClickImage] = useState(null)
  const [cureentIndex, setCureentIndex] = useState(null)

  const handelClick = (item, index) => {
    setCureentIndex(index);
    setClickImage(item.GImage);
    // console.log(clickImage)
    // console.log(cureentIndex)
  };

  // const some = GalleryData.length
  // console.log(some)

  const handelRotationRight = () => {
    const totallength = GalleryData.length;

    if (cureentIndex + 1 >= totallength) {
      setCureentIndex(0);
      const newurl = GalleryData[0].GImage;
      setClickImage(newurl)
      return;
    };
    const newIndex = cureentIndex + 1;
    const newUrl = GalleryData.filter((item) => {
      return GalleryData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].GImage;
    setClickImage(newItem);
    setCureentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totallength = GalleryData.length;
    if (cureentIndex === 0) {
      setCureentIndex(totallength - 1);
      const newUrl = GalleryData[totallength - 1].GImage;
      setClickImage(newUrl);
    }
    const newIndex = cureentIndex - 1;
    const newUrl = GalleryData.filter((item) => {
      return GalleryData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].GImage;
    setClickImage(newItem);
    setCureentIndex(newIndex);
  };

  return (
    <>


      <MetaTags>
        <title>Hair Treatment For Men | D-Posh Skin Hair & Laser Clinic</title>
        <meta title="Hair Treatment For Men | D-Posh Skin Hair & Laser Clinic" />
        <meta
          name="description"
          content="Unlock the secrets to great-looking hair with our specialized Hair Treatment For Men. Achieve thicker, stronger hair with our proven techniques."
        />
        <meta
          name="keywords"
          content="skin care clinic,skin care treatment for men,laser skin care clinic,hair treatment in andheri,hair treatment for men,skin care management in andheri,laser hair removal in andheri,cosmatic dermatology in andheri,best skin care management,skin care hospital."
        />
        <link rel="canonical" href="https://dposhskinclinic.com/hair-treatment-for-men" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Hair Treatment For Men | D-Posh Skin Hair & Laser Clinic" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dposhskinclinic.com/" />
        <meta property="og:description" content="Unlock the secrets to great-looking hair with our specialized Hair Treatment For Men. Achieve thicker, stronger hair with our proven techniques." />
        <meta property="og:image" content="https://dposhskinclinic.com/static/media/logo.b91fca5916adf1129377.png" />
      </MetaTags>


      {/* //////////////////// Section 1 ////////////////////////// */}

      <section className='Gallery-Section1'>
        <div className='Gallery-Section-Banner'>
          <div className='Gallery-Section-Image'>
            {/* <img/> */}
          </div>
          <div className='Gallery-Section-text'>
            <h1>Gallery</h1>
          </div>
        </div>
      </section>

      {/* //////////////////// Section 1 ////////////////////////// */}

      <section className='Image-Gallery'>
        <div className='Image-Gallery-title'>
          <h4>Image gallery</h4>
          <h3>Image gallery</h3>
        </div>
        <div className='container' style={{ marginBottom: "30px" }}>
          <div className='row'>
            {
              GalleryData.map((item, index) => {
                return (
                  <>
                    <div className='col-lg-4 col-md-4 col-sm-6'>
                      {/* <Zoom> */}
                      <div className='G-Images' key={index}>
                        <img src={item.GImage} alt="best skin tratment" onClick={() => handelClick(item, index)} />
                      </div>
                      {/* </Zoom> */}
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>
      {clickImage && (
        <Model
          clickImage={clickImage}
          handelRotationRight={handelRotationRight}
          setClickImage={setClickImage}
          handelRotationLeft={handelRotationLeft}
        />
      )}

      <Footer />

    </>
  )
}

export default GalleryPage