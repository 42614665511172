
import './App.css';
import NavBar from './Component/NavBar';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './Pages/HomePage';
import Services from './Pages/Services';
import AboutUS from './Pages/AboutUS';
import ContactUs from './Pages/ContactUs';
import ServicesInfo from './Component/ServicesInfo';
import GalleryPage from './Pages/GalleryPage';

import ServicesData from './Component/ServicesData';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/skin-care-clinic' element={<Services />} />
          <Route path='/hair-treatment-for-men' element={<GalleryPage />} />
          <Route path='/laser-hair-removale-in-juhu' element={<AboutUS />} />
          <Route path='/contact-us' element={<ContactUs />} />


          {/* ////////// */}

          {ServicesData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Path}
              element={<ServicesInfo />}
            />
          ))}

          <Route path='/tread-face-treatment-in-juhu-mumbai' element={<ServicesInfo />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
